import { Col } from "react-bootstrap";
import { useState, useEffect, useCallback, useMemo } from "react";

export const Finestra = () => {
    const ruotaTesto = useMemo(() => [
        "Ho navigato attraverso firewall e codici di sicurezza, solo per trovare nel tuo cuore la bellezza e la passione di un tramonto italiano", 
        "Come un firewall che protegge preziosi dati, così proteggerò la nostra Patria, il mio amore per lei è la mia linea di difesa più forte", 
        "Il mio codice è cifrato, impenetrabile e prezioso, la chiave segreta che difende il mio cuore e la sicurezza della nostra Patria"
    ], []);
    
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(200 - Math.random() * 100);
    const period = 5000; // 5 secondi tra una parola e l'altra

    const tick = useCallback(() => {
        let i = loopNum % ruotaTesto.length;
        let fullText = ruotaTesto[i];
        let updateText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
        
        setText(updateText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta / 2);
        }

        if (!isDeleting && updateText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updateText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }, [loopNum, isDeleting, text, period, ruotaTesto]);

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => { clearInterval(ticker) };
    }, [text, delta, tick]);

    return (
        <Col lg={12}>
            <div className="bollettino wow slideInUp">
                <h3><em><span className="txt-rotate" dataPeriod="1000"><span className="wrap">{text}</span></span></em></h3>
            </div>
        </Col>
    );
}
