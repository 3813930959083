import { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import telefono from "../Assets/Img/Contattami.jpg"
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input'
import italiano from 'react-phone-number-input/locale/it'

export const Contatto = () => {

    //Campo verifica reCAPTCHA:
    const captchaRef = useRef(null);
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const [captchaCompleted, setCaptchaCompleted] = useState(false);

    const formDettagliPreliminari = {
        appellativo: '',
        dinastia: '',
        postaElettronica: '',
        contattoTelefonico: '',
        messaggio: ''    
    };

    // Stato per tenere traccia se ogni campo è valido o meno
    const [validFields, setValidFields] = useState({
        appellativo: true,
        dinastia: true,
        postaElettronica: true,
        contattoTelefonico: true,
        messaggio: true
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formDettagli, setFormDettagli] = useState(formDettagliPreliminari);
    const [testoTasto, setTestoTasto] = useState('Spedisci');
    const [isNotValid, setIsNotValid] = useState(true);

    const onFormUpdate = (categoria, valore) => {
        //Controllo le informazioni inserite

        const regexAppelativoDinastia = /^[a-zA-Z]*$/;
        const regexPostaElettronica = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const regexContattoTelefonico = /^\+?[1-9]\d{1,14}$/;
        const regexMessaggio = /^([A-Za-z. ,?!àèìòùÀÈÌÒÙ])/g;

        let isValid = true;

        switch(categoria) {
            case "appellativo":
                isValid = regexAppelativoDinastia.test(valore);
                setValidFields(prevState => ({
                    ...prevState,
                    appellativo: isValid
                }));
                break;
            case "dinastia":
                isValid = regexAppelativoDinastia.test(valore);
                setValidFields(prevState => ({
                    ...prevState,
                    dinastia: isValid
                }));
                break;
            case "postaElettronica": 
                isValid = regexPostaElettronica.test(valore);
                setValidFields(prevState => ({
                    ...prevState,
                    postaElettronica: isValid
                }));
                break;
            case "contattoTelefonico":
                isValid = regexContattoTelefonico.test(valore);
                setValidFields(prevState => ({
                    ...prevState,
                    contattoTelefonico: isValid
                }));
                break;
            case "messaggio":
                isValid = regexMessaggio.test(valore);
                setValidFields(prevState => ({
                    ...prevState,
                    messaggio: isValid
                }));
                break;
            default:
                console.log("Errore nella selezione delle categorie, verifica.");

        }

        setFormDettagli({
            ...formDettagli,
            [categoria]: valore
        });

        // Controlla se tutti i campi sono validi
        const allFieldsValid = Object.values(validFields).every(fieldIsValid => fieldIsValid);
        // Imposta isNotValid in base a se tutti i campi sono validi
        setIsNotValid(!allFieldsValid);

    }

    const invioMessaggio = async (e) => {
        e.persist();
        e.preventDefault();
        if (isNotValid) {
            return;
        }
        setTestoTasto('Invio in corso...');
        setIsSubmitting(true);
        formDettagli['g-recaptcha-response'] = captchaResponse;
        emailjs
            .send(
                process.env.REACT_APP_SERVICE_ID, 
                process.env.REACT_APP_TEMPLATE_ID, 
                formDettagli, 
                process.env.REACT_APP_PUBLIC_KEY
            )
            .then(
                (result) => {
                    setCaptchaCompleted(false);
                    captchaRef.current.reset();
                    setFormDettagli(formDettagliPreliminari);
                    setTestoTasto('Messaggio inviato!');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setTestoTasto('Spedisci');
                    }, 5000); // Nascondi il messaggio di risposta dopo 5 secondi
                },
                (error) => {
                    console.log(process.env.REACT_APP_PUBLIC_KEY);
                    setCaptchaCompleted(false);
                    captchaRef.current.reset();
                //    setFormDettagli(formDettagliPreliminari);
                    if (!captchaCompleted) {
                        setTestoTasto('E\' richiesta la verifica aggiuntiva prima di inviare i dati.');
                    } else {
                        setTestoTasto('Qualcosa è andato storto, si prega di riprovare più tardi.');
                    }
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setTestoTasto('Spedisci');
                    }, 5000); // Nascondi il messaggio di risposta dopo 5 secondi
                }
            );
    }

    return (
        <section className="contatto" id="contatto">
            <Container>
                <Row className="align-item-center">
                    <Col md={6}>
                        <img id="img-cellulare" src={telefono} alt="Contatto" />
                    </Col>
                    <Col md={6}>
                        <div className="sfondo-contatto">
                            <h2>Contattami</h2>
                            <form onSubmit={invioMessaggio}>
                                <Row>
                                    <Col sm={6} className="px-1">
                                        <input type="text" className={validFields.appellativo ? "" : "errore"} value={formDettagli.appellativo} placeholder="Appellativo" onChange={(e) => onFormUpdate('appellativo', e.target.value)} required/>
                                    </Col>
                                    <Col sm={6} className="px-1">
                                        <input type="text" className={validFields.dinastia ? "" : "errore"} value={formDettagli.dinastia} placeholder="Dinastia" onChange={(e) => onFormUpdate('dinastia', e.target.value)} required/>
                                    </Col>
                                    <Col sm={6} className="px-1">
                                        <input type="email"className={validFields.postaElettronica ? "" : "errore"} value={formDettagli.postaElettronica} placeholder="Indirizzo di posta elettronica" onChange={(e) => onFormUpdate('postaElettronica', e.target.value)} required/>
                                    </Col>
                                    <Col sm={6} className="px-1">
                                        <PhoneInputWithCountry className={validFields.contattoTelefonico ? "" : "errore"} defaultCountry="IT" labels={italiano} selectArrow={<div style={{marginRight: '0.5em'}}>▼</div>} selectStyle={{fontSize: '1.25em'}} placeholder="Contatto telefonico" value={formDettagli.contattoTelefonico} onChange={phone => onFormUpdate('contattoTelefonico', phone)} maxLength="15" required />
                                    </Col>
                                    <Col>
                                        <textarea row="6" className={validFields.messaggio ? "" : "errore"} value={formDettagli.messaggio} placeholder="Comunicazione..." onChange={(e) => onFormUpdate('messaggio', e.target.value)} required></textarea>
                                        <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        theme="dark"
                                        ref={captchaRef}
                                        onChange={(response) => {
                                            setCaptchaResponse(response);
                                            setCaptchaCompleted(true);
                                        }}
                                        />
                                        <button type="submit" disabled={isSubmitting}><span>{testoTasto}</span></button>
                                    </Col>    
                                </Row>    
                            </form>
                        </div>     
                    </Col>
                </Row>
            </Container>
        </section>
    )
}