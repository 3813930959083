import { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import 'animate.css';
import TrackVisibility from "react-on-screen"; // Verifica se i componenti racchiusi in questo tag della libreria sono visibili sullo schermo o meno.
import imgIntestazione from "../Assets/Img/maschera_intastazione.png";

export const Intestazione = () => {
    const [effettoUsato, setEffettoUsato] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const ruotaTitoli = useMemo(() => ["Full Stack Developer", "Ethical Hacker", "Red Teamer"], []);
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000; // 2 secondi tra una parola e l'altra

    const tick = useCallback(() => { 
        let i = loopNum % ruotaTitoli.length; 
        let fullText = ruotaTitoli[i]; 
        let updateText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1); 
        setText(updateText); 

        if (isDeleting) { 
            setDelta(prevDelta => prevDelta / 2); 
        } 
        
        if (!isDeleting && updateText === fullText) { 
            setIsDeleting(true); 
            setDelta(period); 
        } else if (isDeleting && updateText === '') { 
            setIsDeleting(false); 
            setLoopNum(loopNum + 1); 
            setDelta(500); 
        } 
    }, [loopNum, isDeleting, text, period, ruotaTitoli]);

    // Effetto movimento caratteri del testo dell'intestazione
    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => { clearInterval(ticker) };
    }, [text, delta, tick]);

    // Callback per aggiornare effettoUsato basato su visibilità
    const handleVisibilityChange = (isVisible) => {
        if (isVisible && !effettoUsato) {
            setEffettoUsato(true);
        }
    };

    return (
        <section className="intestazione" id="presentazione">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                            {({ isVisible }) => {
                                handleVisibilityChange(isVisible);
                                return (
                                    <div className={effettoUsato ? "animate__animated animate__fadeIn animate__delay-1s" : ""}>
                                        <span className="tagline">Benvenuto</span>
                                        <h1>{'Ciao, sono AngelDragon'}<br /><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Full Stack Developer", "Ethical Hacker", "Red Teamer" ]'><span className="wrap">{text}</span></span></h1>
                                        <p>Un devoto esploratore del vasto universo della programmazione e della sicurezza informatica, con un interesse particolare per le invenzioni e le innovazioni. Con un linguaggio delicato ed elegante, mi avvicino a queste sfide con una seducente curiosità e un'incessante sete di conoscenza, cercando sempre di offrire il meglio di me stesso nel mio campo.</p>
                                        <button onClick={() => window.location.href = '#contatto'}>Contattami<ArrowRightCircle size={25}/></button>        
                                    </div>
                                );
                            }}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <TrackVisibility>
                            {({ isVisible }) => {
                                handleVisibilityChange(isVisible);
                                return (
                                    <div className={effettoUsato ? "animate__animated animate__fadeIn animate__delay-1s" : ""}>
                                        <img src={imgIntestazione} alt="Immagine d'intestazione" />
                                    </div>
                                );
                            }}    
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
