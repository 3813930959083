import './App.css';
import './Errore.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./Components/NavBar";
import { Intestazione } from './Components/Instestazione';
import { Talenti } from './Components/Talenti';
import { Lavori } from './Components/Lavori/Lavori';
import { Contatto } from './Components/Contatto';
import { PiePagina } from './Components/PiePagine';

function App() {
  return (
    <div className="App" id="app">
      <NavBar />
      <Intestazione /> 
      <Talenti />
      <Lavori />
      <Contatto />
      <PiePagina />
    </div>
  );
}

export default App;
