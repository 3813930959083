import { Col, Container, Tab, Nav, Row } from "react-bootstrap";
import { CartolineLavori } from "./CartolineLavori";
import sfondoLavori from "../../Assets/Img/color-sharp2.png";
import Nexi from "../../Assets/Img/Nexi.png";
import Vodafone from "../../Assets/Img/vodafone.png";
import Intesi from "../../Assets/Img/intesi-group.png";
import NMPI from "../../Assets/Img/nmpi.png";
import LagunaBlu from "../../Assets/Img/LagunaBlu.png";
import Investimenti from "../../Assets/Img/Investimenti.png"; 
import crescita from "../../Assets/Img/Crescita.png"; 
import ItaliaPatria from "../../Assets/Img/ItaliaPatria.png"; 
import spirito from "../../Assets/Img/Spirito.png"; 

export const Lavori = () => {
    // Creo una lista in formato JSON per i lavori
    const mieiLavori = [
        {
            titolo: "Nexi S.p.A",
            descrizione: "Sviluppatore back-end, gestore DB",
            immagine: Nexi
        },
        {
            titolo: "Vodafone Group Plc",
            descrizione: "Sviluppatore Front-end",
            immagine: Vodafone
        },
        {
            titolo: "Intesi Group S.p.A",
            descrizione: "Sviluppatore Full Stack e gestione DB",
            immagine: Intesi
        }
    ];

    const lavoriSecondari = [
        {
            titolo: "Nucleo Mobile di Pronto Intervento - Protezione Civile Nazionale",
            descrizione: "Istruttore Scelto presso NMPI - Capo Equipaggio - sez. Nautica",
            immagine: NMPI
        },
        {
            titolo: "Assistente Bagnanti",
            descrizione: "Occasionalmente Assistente Bagnanti presso piscine o località balneabili",
            immagine: LagunaBlu
        },
        {
            titolo: "Investitore Privato",
            descrizione: "Investitore privato in progetti di sviluppo Nazionale",
            immagine: Investimenti
        }
    ]

    const passioniPersonali = [
        {
            titolo: "Crescita Personale",
            descrizione: "Sviluppo della ragione e conoscenza",
            immagine: crescita
        },
        {
            titolo: "Benessere Nazionale",
            descrizione: "Sviluppo dei progetti per la crescita della Nazione",
            immagine: ItaliaPatria
        },
        {
            titolo: "Lavoro spirituale",
            descrizione: "Conoscenza del proprio \"se\" e della funzione della fisica quantisitica",
            immagine: spirito
        }
    ]

    return (
        <section className="lavori" id="lavori">
            <Container>
                <Row>
                    <Col size={12}>
                        <h2>I miei lavori</h2>
                        <p>Nel corso della mia carriera, ho avuto l'opportunità di tessere insieme i fili della mia passione e competenza in progetti che riflettono la mia abilità come hacker, Red Teamer, e Full Stack Developer. Ogni sfida affrontata è stata un gradino verso la perfezione, un passo avanti nella danza affascinante della programmazione e sicurezza informatica. Ogni progetto ha rappresentato un passo verso il raggiungimento dei miei obiettivi, un'occasione per imparare e crescere, e un'opportunità per mettere al servizio del mondo l'eccellenza della tecnologia 'made in Italy'.</p>
                        <Tab.Container id="linguette-lavori" defaultActiveKey="primo">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="primo">Impiego principale</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="secondo">Impegno secondario</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="terzo">Passioni personali</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="primo">
                                    <Row>
                                        {
                                            mieiLavori.map((lavoro, index) => {
                                                return (
                                                    <CartolineLavori
                                                    key={index}
                                                    {...lavoro}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="secondo">
                                    <Row>
                                        {
                                            lavoriSecondari.map((lavoro, index) => {
                                                return (
                                                    <CartolineLavori 
                                                    key={index}
                                                    {...lavoro}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="terzo">
                                    <Row>
                                        {
                                            passioniPersonali.map((passioni, index) =>{
                                                return (
                                                    <CartolineLavori
                                                    key={index}
                                                    {...passioni}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={sfondoLavori} alt="sfondo"/>
        </section>
    )
}