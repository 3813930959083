import { Col } from "react-bootstrap";

export const CartolineLavori = ({titolo, descrizione, immagine}) => {
    return (
        <Col sm={6} md={4}>
            <div className="lavori-img-bx">
                <img src={immagine} alt="cartoline lavori"/>
                <div className="lavori-img-txt">
                    <h4>{titolo}</h4>
                    <span>{descrizione}</span>
                </div>
            </div>
        </Col>
    )
}