import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import circonferenzaTalenti1 from "../Assets/Img/sviluppatore.svg";
import circonferenzaTalenti2 from "../Assets/Img/hacker.svg";
import circonferenzaTalenti3 from "../Assets/Img/RedTeamer.svg";
import sfondoTalenti from "../Assets/Img/color-sharp.png";


export const Talenti = () => {
    
    const responsive = {
        superLargeDesktop: {
          // I nomi sono intercambiabili
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    
    return(
        <section className="talenti" id="talenti">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="talenti-bx">
                            <h2>I miei talenti</h2>
                            <p>Nell'Universo della tecnologia navigo con la precisione di un hacker, l'ingegnosità di un Red Teamer e la versatilità di un Full Stack Developer, unendo competenze e talenti per disegnare il futuro dell'innovazione</p>
                            <Carousel responsive={responsive} infinite={true} className="diapositive-talenti">
                                <div className="elemento">
                                    <img src={circonferenzaTalenti1} alt="Immagine dell'elemento Full Stack" />
                                    <h5>Full Stack Developer</h5>
                                </div>
                                <div className="elemento">
                                    <img src={circonferenzaTalenti2} alt="Immagine dell'elemento Ethical Hacker" />
                                    <h5>Ethical Hacker</h5>
                                </div>
                                <div className="elemento">
                                    <img src={circonferenzaTalenti3} alt="Immagine dell'elemento Red Teamer" />
                                    <h5>Red Teamer</h5>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <img className="sfondo-talenti" src={sfondoTalenti} alt="Immagine di sfondo dei Talenti" /> 
        </section>
    )
}