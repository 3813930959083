import { Container, Row, Col } from "react-bootstrap";
import logo from "../Assets/Img/AngelDragonLogo-no-sfondo.png";
import circonferenzaTalenti3 from "../Assets/Img/RedTeamer.svg";
import { Finestra } from "./Sezione_aggiuntiva/Finestra";
import { SuitHeartFill } from 'react-bootstrap-icons';



export const PiePagina = () => {
    return (
        <footer className="pie">
            <Container>
                <Row className="align-item-center">
                    <Finestra />
                    <Col size={12} sm={6}>
                        <img src={logo} alt="Logo" className="logo-pie"/>
                    </Col>
                    <Col size={12} sm={6} className="text-center text-sm-end">
                        <img src={circonferenzaTalenti3} alt="loghetto terminale aggiunto"/>                
                    </Col>
                </Row>
            </Container>
            <p><em>Sviluppato con <SuitHeartFill color="red" className="cuore" /> da AngelDragon !</em></p>
        </footer>
    )
}