// Componente di funzionalità - Functional Component
import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

// Importo le mie immagini:
import logoFull from '../Assets/Img/AngelDragonLogoSolo_img-no-sfondo.png';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('presentazione'); // Costante per inizializzare la pagina activeLink = nome scelto
    const [scorrimento, setScorrimento] = useState(false);

    // Creazione funzione per cambiare tema sullo scorrimento
   useEffect(() => {
        const onScorrimento = () => {
            if (window.scrollY > 50) {  // Se lo scorrimento ha superato il 50% della pagina Y => verso il basso | X = scorrimento laterale
                setScorrimento(true);
            } else {
                setScorrimento(false);
            }
        }
        
        window.addEventListener("scroll", onScorrimento)

        return () => window.removeEventListener("scroll", onScorrimento)
   }, [])
    
   const onUpdateLinkAttivo = (value) => {
    setActiveLink(value);
   }


   // Vista per cellulari <Navbar.Toggle aria-controls="basic-navbar-nav">
    return (
        <Navbar expand="lg" className={scorrimento ? "scorrimento" : ""}>
        <Container>
          <Navbar.Brand href="#presentazione">
            <img src={logoFull} alt="Logo Completo" className="logo-full" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle> 
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#presentazione" className={activeLink === 'presentazione' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateLinkAttivo('presentazione')}>Presentazione</Nav.Link>
              <Nav.Link href="#talenti" className={activeLink === 'talenti' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateLinkAttivo('talenti')}>I miei talenti</Nav.Link>
              <Nav.Link href="#lavori" className={activeLink === 'lavori' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateLinkAttivo('lavori')}>I miei lavori</Nav.Link>
            {/*  <Nav.Link href="#progetti" className={activeLink === 'progetti' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateLinkAttivo('progetti')}>I miei progetti</Nav.Link> */}
            </Nav>
            <span className="navbar-text">
                <button className="contatto-diretto" onClick={() => window.location.href = '#contatto'}><span>Contattami</span></button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}